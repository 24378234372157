import React from "react";
import "../PagesCSS/Loader.css";

function LoadingScreen() {
  return (
    <>
      <div className="loaderColor vw-100 vh-100 d-flex justify-content-center">
        <img alt="logo" className="" src="image/logo white.svg" />
      </div>
    </>
  );
}

export default LoadingScreen;

import { broadcastLangMapObj, broadcastLanguages } from "./Const";

export function getCurrentBroadcastLanguage() {
  let bcLangObj;
  const broadcastLangObjStr = localStorage.getItem("broadcastLangObj");

  if (broadcastLangObjStr) {
    bcLangObj = JSON.parse(broadcastLangObjStr);
  } else {
    const bcLanglocalStorageVal = localStorage.getItem("broadcastLanguage");

    bcLangObj = broadcastLangMapObj[bcLanglocalStorageVal]
      ? broadcastLangMapObj[bcLanglocalStorageVal]
      : broadcastLanguages[0];
  }

  return bcLangObj;
}

export function getCurrentBroadcastProgramm() {
  let bcProgrammObj;
  const broadcastProgrammObjStr = localStorage.getItem("broadcastProgrammObj");

  if (broadcastProgrammObjStr) {
    bcProgrammObj = JSON.parse(broadcastProgrammObjStr);
  } else {
    bcProgrammObj = { value: "morning_lesson", label: "Morning lesson" };
  }

  return bcProgrammObj;
}

export function parseMqttMessage(mqttMessage) {
  if (mqttMessage) {
    try {
      if (typeof mqttMessage === "string") {
        let msgJson = JSON.parse(mqttMessage);

        return msgJson;
      }
    } catch (err) {
      console.log(err);
    }

    return mqttMessage;
  }
}

export function getSubtitleMqttTopic(broadcastProgrammCode, broadcastLangCode) {
  return `subtitles/${broadcastProgrammCode}/${broadcastLangCode}/slide`;
}

export function getQuestionMqttTopic(broadcastProgrammCode, broadcastLangCode) {
  return `subtitles/${broadcastProgrammCode}/${broadcastLangCode}/question`;
}

export const subtitlesDisplayModeTopic = "subtitles/display_mode";

export const getMqttClientId = () => {
  let clientId;
  const ssMqttClientId = sessionStorage.getItem("mqttClientId");

  if (ssMqttClientId) {
    clientId = ssMqttClientId;
  } else {
    clientId = `kab_subtitles_${Math.random().toString(16).substr(2, 8)}`;
    sessionStorage.setItem("mqttClientId", clientId);
  }

  return clientId;
};

export function languageIsLtr(langCode) {
  let isLeftToRight = true;

  if (langCode) {
    const lnagObj = broadcastLangMapObj[langCode];

    if (lnagObj) {
      isLeftToRight = !(lnagObj.isLtr === false);
    }
  }

  return isLeftToRight;
}

export function messageIsLtr(message) {
  let isLeftToRight = true;

  if (message) {
    if (typeof message.isLtr !== "undefined") {
      isLeftToRight = !(message.isLtr === false);
    } else if (typeof message.lang !== "undefined") {
      isLeftToRight = languageIsLtr(message.lang);
    } else {
      const langObj = getCurrentBroadcastLanguage();
      isLeftToRight = languageIsLtr(langObj.value);
    }
  }

  return isLeftToRight;
}
